@import '../../../index';
@import "~react-image-gallery/styles/scss/image-gallery.scss";

.profile-content{
    width:100%;
    height: 100%;
    position: absolute;
    // display: grid;
    grid-template-rows: auto auto auto max-content;
    color: $theme-5; 
    /* background-color:rgb(255, 255, 255); */
}
.profile-content > div{
    padding:8px
}
.profile-header{
    display: grid;
    grid-template-columns: auto 1fr;
    grid-template-rows: repeat(2, 1fr);
    grid-template-areas:
    'd1 d2'
    'd1 d3';
    margin-top: 80px;
}
.profile-header .profile-pic {
    width: auto;
    height: auto;
    grid-area: d1;
}
.profile-header .profile-name {
    grid-area: d2;
    padding-top: 15px;
    font-weight: bold;
}
.profile-header .profile-bio{
    grid-area: d3;
}
.profile-loading{
    margin-top: 200px;
}
.image-slider{
    width:270px;
    height: auto;
    position: relative;
    margin: auto;
    margin-bottom: 16px;
}

.rsis-image {
    width: 100%;
    height: 100%;
}
  
.profile-services > div {
    margin-left: 5px;
    margin-top: 5px;
}
.profile-forbiddens > div {
    margin-left: 5px;
    margin-top: 5px;
}
.profile-chanels{
    position: fixed;
    bottom: 0px;
    width: 100%;
    height: auto;
    direction: rtl;
    right: 0px;
}
.profile-chanels > div{
    width: 56px;
    height: 56px;
    margin: 8px;
    border-radius: 30%;
    display: inline-flex;
    font-size: xx-large;
}
.profile-chanels > div > a {
    margin: auto;
    font-size: larger;
}
.whatsapp{
    background-color:  #25d366 !important;
}
.whatsapp a{
    color: bisque;
}
.direct{
    background-color: #54b1f0;
}
.profile-footer{
    height:70px;
    visibility: hidden;
}