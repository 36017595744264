@import '../../index.scss';

header {
  border-bottom: 1px solid #e6dbdb73;
  color: rgba(255, 255, 255, .25);
  text-align: center;
  margin: 0;
  height: 80px;
  position: fixed;
  z-index: 1;
  background-color: $theme-1;
  width: 100%;

}

header .logo {}

header .back-btn {
  position: absolute;
  top: 0;
  bottom: 0;
  font-size: large;
  border-right: 1px solid #e6dbdb73;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 20px;
  color: $theme-5;
}

@font-face {
  font-family: 'GreatVibes';
  font-style: normal;
  font-weight: 400;
  src: local("GreatVibes"),
    url("../fonts/GreatVibes/GreatVibes-Regular.ttf") format("truetype");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

.font-effect-neon {
  text-shadow: 0 0 0.1em #fff, 0 0 0.2em #fff, 0 0 0.3em #fff, 0 0 0.4em #f7f, 0 0 0.6em #f0f, 0 0 0.8em #f0f, 0 0 1.0em #f0f, 0 0 1.2em #f0f;
  color: #0e0909;
  font-family: 'GreatVibes';
}

// .font-effect-outline {
//     text-shadow:0 1px 1px #000, 0 -1px 1px #000, 1px 0 1px #000, -1px 0 1px #000;
//     color: #fff;
//   }
// .font-effect-emboss {
//     text-shadow: 0px 1px 1px #fff, 0 -1px 1px #000;
//     color: #ddd;
//   }
// .font-effect-shadow-multiple {
//     text-shadow: .04em .04em 0 #fff,.08em .08em 0 #aaa;
//   }
   