
.status-bar {
    background-color: rgba(38, 6, 12, 1);
    box-sizing: border-box;
    display: flex;
    /* flex-direction: row; */
    overflow-y: hidden;
    padding: 10px 0;
    /* padding-top: 30px; */
    /* position: relative; */
    border-bottom: 1px solid #e6dbdb73;
    padding-left: 10px;
    margin-top: 80px;
}

.status-item{
    display: flex;
    flex-direction: column;
    width: 64px;
    align-self: center;
    align-items: center;
    margin-right: 10px;
	position: relative;
}
.status-item .item-name{
    color: rgba(166, 3, 47, 1);
}
.status-bar a:link{text-decoration: none;}