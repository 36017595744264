body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color:black;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

$theme-1: rgba(38, 6, 12, 1); 
$theme-2: rgba(242, 5, 68, 1); 
$theme-3: rgba(166, 3, 47, 1); 
$theme-4: rgba(89, 2, 25, 1); 
$theme-5: rgba(242, 5, 159, 1); 
