.map-filter{
    background-color: white;
    height: inherit;
    position: absolute;
    overflow: auto;
    width: 100%;
}
.leaflet-container {
    height: 200px;
    width: 100%;
    z-index:0;
  } 
.map-filter button{
    right: 10px;;
    bottom: 10px;
    position: absolute;

}