@import '../../../index';



.public-items{
    width: 100%;
    overflow: scroll;
    /* background: white; */
    padding-bottom: 100px;
}
.public-items > div{
    width: 350px;
    height: 100%;
    margin: auto;
}
.ad-item{
    color: $theme-3;
}

.irregular .ad-item{
    margin: 5px;
    border: 1px solid darkmagenta; 
    box-shadow: darkmagenta 0px 22px 70px 4px;
}
.irregular .ad-item img{
    width: 100%;
    height: 100%;
	filter: blur(7px);
	-webkit-filter: blur(4px);
}
.public-items .box1{
    width:250px;
    height: 75px;
    float: left;
}
.public-items .box2{
    width:75px;
    height: 75px;
    float: right;
}
.public-items .box3{
    width:100px;
    height: 75px;
    float: left;
}
.public-items .box4{
    width:220px;
    height: 150px;
    float: right;
}
.public-items .box5{
    width:100px;
    height: 236px;
    float: left;
}
.public-items .box6{
    width:105px;
    height: 75px;
    float: right;
}
.public-items .box7{
    width:105px;
    height: 75px;
    float: right;
}
.public-items .box8{
    width: 223px;
    height: 75px;
    float: right;
}
.public-items .box9{
    width:75px;
    height: 75px;
    float: left;
}
.public-items .box10{
    width:250px;
    height: 75px;
    float: right;
}
.public-items .box11{
    width:220px;
    height: 150px;
    float: left;
}
.public-items .box12{
    width:100px;
    height: 75px;
    float: right;
}
.public-items .box13{
    width:100px;
    height: 236px;
    float: right;
}
.public-items .box14{
    width:105px;
    height: 75px;
    float: left;
}
.public-items .box15{
    width:105px;
    height: 75px;
    float: left;
}
.public-items .box16{
    width: 223px;
    height: 75px;
    float: left;
}


.regular{
    padding-top: 5px;
}
.regular .ad-item{
    width: 50px;
    height:80px;
    border: 1px solid darkmagenta; 
    float: left;
    margin-left: 5px;
    margin-bottom: 5px;
}
.regular .ad-item img{
    width: 100%;
    height: 100%;
}






.vip{
    padding-top: 5px;
}
.vip .ad-item{
    width: 105px;
    height:165px;
    border: 1px solid rgb(139, 148, 12); 
    float: left;
    margin-left: 5px;
    margin-bottom: 5px;
    position: relative;
}
.vip .badge{
    width:30px;
    height: 30px;
}
.vip .pic{
    position: absolute;
    width: 100%;
    height: 100%;
    filter: blur(10px);
	-webkit-filter: blur(10px);
    top: 0;
    left: 0;
}