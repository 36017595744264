main{
    display: grid;
    grid-template-rows: auto auto auto max-content;
    background-color:rgba(38, 6, 12, 1);
}
.insta{
    position: fixed;
    margin: 8px;
    width:auto;
    height: auto;
    bottom: 0px;
    background-color: #00000054;
    border-radius: 20%;
}
.insta img{
    width: 56px;
    height: 56px;
}






/* Color Theme Swatches in RGBA */
.theme-1 { color: rgba(38, 6, 12, 1); }
.theme-2 { color: rgba(242, 5, 68, 1); }
.theme-3 { color: rgba(166, 3, 47, 1); }
.theme-4 { color: rgba(89, 2, 25, 1); }
.theme-5 { color: rgba(242, 5, 159, 1); }

/* Color Theme Swatches in HSLA */

