@import '../../../index';


.tools-container{
    display: grid;
    grid-template-rows: auto auto;
}
.tool{
    visibility: hidden;
    height: 0px;
    /* background-color: #fff; */
    transition: height 1s;
    overflow: scroll;
    border-bottom: 1px solid #e6dbdb73
}
.tool.active{
    visibility: visible;
    height: 200px;
    /* background-color: #fff; */
    transition: height 1s;
    overflow: scroll;
}
.tool-bar{
    display: grid;
    grid-template-columns: auto auto auto 1fr;
    background-color: $theme-1;
    color: $theme-2;
    padding-left: 5px;
    border-bottom: 1px solid #e6dbdb73;
}
.tool-icon{
    margin: 5px;
    margin-right: 0px;
    font-size: x-large;
}
.tool-bar > div{
    display: flex;
    flex-direction: row;
    justify-content: end;
    padding: 5px;
    align-items: center;
}
.tool-bar > div > span{
    margin-left: 5px;
}

.chip-bar{
    background-color: $theme-1;
    /* background-color: aliceblue; */
    box-sizing: border-box;
    display: flex;
    /* flex-direction: row; */
    overflow-y: hidden;
    padding: 10px 0;
    /* padding-top: 30px; */
    /* position: relative; */
    border-bottom: 1px solid #e6dbdb73;
    padding-left: 10px;
    height: auto;
}
.chip-bar > div {
    margin-left: 5px;
}
.place-holder{
    visibility: hidden;
}