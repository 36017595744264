.circle-wrapper {
	position: relative;
    width: 62px;
    height: 62px;
	margin: 8px;
}

.icon {
	position: absolute;
	top: 0px;
	left: 0px;
	color: #fff;
    width: 56px;
    height: 56px;
	top: 3px;
	left: 3px;
	
	// transform: translate(-50%, -50%);
}
.icon img {
	width: 100%;
	height: 100%;
    border-radius: 50%;
	filter: blur(7px);
	-webkit-filter: blur(7px);
	// transform: translate(-50%, -50%);
}

.circle {
	position: absolute;
	top: 0px;
	left: 0px;
	display: block;
	width: 100%;
	height: 100%;
	border-radius: 50%;
	background-clip: content-box;
	// border: 2.5px dashed #4BB543;
	// animation: spin 10s linear infinite;
	background: rgb(241,18,28);
	background: linear-gradient(0deg, rgba(241,18,28,1) 0%, rgba(136,2,139,1) 23%); 
	// visibility: hidden;
}


.circle-wrapper:hover .circle {
	animation: spin 2s linear infinite;
}


// @keyframes rotate {
// 	100% {
// 		transform: rotate(1turn);
// 	}
// }

@keyframes spin { 
	100% { 
	  transform: rotateZ(360deg);
	}
  }