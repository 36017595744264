.filter-container{
    color: #fff;
    height: 100%;
    position: relative;
    overflow: auto;
    width: auto;
}
.filter-container div{
    height: 100%;
    overflow: auto;

}
.filter-container button{
    right: 10px;;
    bottom: 10px;
    position: absolute;

}